import React from "react"
import { graphql } from "gatsby"
import Layout from '../components/layout'
import PageHeader from '../components/page-header';

const PostTemplate = ({data}) => {
    const page = data.wordpressPage;

    return (
        <Layout>
            <PageHeader
                heading={page.title}
                subHeading={page.acf.subheading}
                pageTitle={page.title}
                seoMetaData={page.yoast_meta}
            />
            <div className="generic-content-component">
                <div className="component-wrapper" dangerouslySetInnerHTML={{__html: page.content}}></div>
            </div>
        </Layout>
    );
}

export default PostTemplate

export const pageQuery = graphql`
    query($id: String!) {
        wordpressPage(id: { eq: $id }) {
            title
            content
            acf {
                subheading
            }
            yoast_meta {
                yoast_wpseo_title
                yoast_wpseo_metadesc
                yoast_wpseo_canonical
            }
        }
    }
`;
